import { App } from "@vue/runtime-core";

// this class were copied from ant-input classes
const loadingClassName = "ant-input-disabled"; // class added while the field is at loading state

/*
 * caveat:
 *  - traditional form submission will not work with this directive.
 *  - because some input fields will be `disabled`, their values must be taken from the model
 *  - other details and suggested workaround, if needed, is in #25nxah
 */

export const useFormLoadingDirective = (app: App): App => {
  return app.directive("form-loading", {
    updated: (form, binding) => {
      if (binding.value != binding.oldValue) {
        // loading was triggered
        if (binding.value) {
          // loading started
          // disable all inputs that are not disabled by some other condition
          const inputs = form.querySelectorAll(
            "input:not([disabled]):not([readonly]):not([type='hidden']), select:not([disabled]):not([readonly]), textarea:not([disabled]):not([readonly]), input.ant-calendar-picker, div.ant-select.dropdown-input, .date-picker.ant-calendar-picker, .mobile-input, input.ant-calendar-picker-input, .input-password .ant-input-password, .ant-input-affix-wrapper"
          );

          inputs.forEach(function (element) {
            element.classList.add(loadingClassName);

            if (element.classList.contains("ant-select")) {
              element.classList.add("ant-select-disabled");
              element.style.pointerEvents = "not-allowed !important";
            } else if (
              element.classList.contains("ant-select-selection-search-input")
            ) {
              element.disabled = true;
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-calendar-picker")) {
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-checkbox")) {
              element.classList.add("ant-checkbox-disabled");
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-radio")) {
              element.classList.add("ant-radio-disabled");
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-input-password")) {
              element.setAttribute("disabled", true);
            }

            if (
              element.tagName === "SELECT" ||
              element.tagName == "INPUT" ||
              element.type === "range" ||
              element.type === "radio" ||
              element.type === "checkbox"
            ) {
              element.style.pointerEvents = "not-allowed !important";
              element.disabled = true;
            } else {
              element.readOnly = true;
            }
          });
        } else {
          // loading ended
          // re-enable all inputs that are not disabled by some other condition
          const inputs = form.querySelectorAll(`.${loadingClassName}`);
          inputs.forEach(function (element) {
            if (element.classList.contains("ant-select")) {
              element.classList.remove("ant-select-disabled");
              element.style.pointerEvents = "";
            } else if (element.classList.contains("ant-calendar-picker")) {
              element.style.pointerEvents = "";
            } else if (element.classList.contains("ant-checkbox")) {
              element.classList.add("ant-checkbox-disabled");
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-radio")) {
              element.classList.add("ant-radio-disabled");
              element.style.pointerEvents = "not-allowed !important";
            } else if (element.classList.contains("ant-input-password")) {
              element.removeAttribute("disabled");
            }

            if (
              element.tagName === "SELECT" ||
              element.tagName === "INPUT" ||
              element.type === "range" ||
              element.type === "radio" ||
              element.type === "checkbox"
            ) {
              element.disabled = false;
            } else {
              (element as HTMLInputElement).readOnly = false;
            }
            element.classList.remove(loadingClassName);
          });
        }
      }
    },
  });
};
