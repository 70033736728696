
import { defineComponent, ref, watchEffect } from "vue";
import MultiAccountSelector, {
  MultiAccountSelectorMode,
} from "@/shared/components/MultiAccountSelector/MultiAccountSelector.vue";
import { useRouter } from "vue-router";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import { useI18n } from "vue-i18n";
import { useSidebar } from "@/shared/composables/useSidebar";
import routeNames from "@/web/router/routeNames";
import { config } from "@/shared/utils/config";
import { openInNewTab } from "@/shared/utils/browser";

export default defineComponent({
  emits: ["route-changed"],
  props: {
    isCollapsed: {
      type: Boolean,
    },
    developer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { sidebarRoutes } = useSidebar();
    const router = useRouter();
    const selectedKeys = ref<string[]>([]);

    watchEffect(() => {
      /**
       * Update the active item to the current route
       * if there are no active item set yet.
       */
      const path = router.currentRoute.value.path;
      if (
        isEmpty(selectedKeys.value) ||
        first(selectedKeys.value) === "/" ||
        !selectedKeys.value.includes(path)
      ) {
        // Only use the base route for setting the active sidebar item
        selectedKeys.value = [`/${path.split("/")[1]}`];
      }
    });

    return {
      t,
      config,
      selectedKeys,
      sidebarRoutes,
      MultiAccountSelectorMode,
      handleSidebarMode: () => {
        if (props.developer) {
          // on developer mode, go to profile
          router.push({ name: routeNames.profile });
        } else {
          // on profile mode, go to develoepr
          router.push({ name: routeNames.applications });
        }
      },
    };
  },
  methods: { openInNewTab },
  components: { MultiAccountSelector },
});
