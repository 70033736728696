import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { loginGql } from "@/api/onboarding/login";
import {
  Login,
  LoginVariables,
  Login_login_Authentication,
  Login_login_Authentication_user,
} from "@/api/onboarding/__generated__/Login";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import isEmpty from "lodash/isEmpty";
import { LoginInput } from "../../../__generated__/globalTypes";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { logoutGql } from "@/api/onboarding/logout";
import {
  Logout,
  Logout_logout_GenericSuccess,
} from "@/api/onboarding/__generated__/Logout";
import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { UserPendingAction } from "./../../../__generated__/globalTypes";
import { authenticate2faGql } from "@/api/onboarding/authenticated2fa";
import {
  Authenticate2fa,
  Authenticate2faVariables,
  Authenticate2fa_authenticate2fa_User,
} from "@/api/onboarding/__generated__/Authenticate2fa";
import clone from "lodash/clone";
import { aliasRouteRegisterOnBoarding } from "@/shared/router/routePaths";
import { useExternalWallet } from "@/shared/composables/wallet/useExternalWallet";
import { getOrCreateDeviceId } from "@/shared/utils/browser";

type LoginState = {
  user: Partial<Login_login_Authentication_user>;
  twoFactorRequired: boolean;
};

const emptyLoginState = {
  user: {
    id: "",
    username: "",
    created: "",
    wallet: null,
    name: "",
    avatar40: null,
    avatar80: null,
    avatar400: null,
    pendingAction: null,
  },
  twoFactorRequired: false,
};

export function useAuthentication() {
  const loginState = reactive<LoginState>(emptyLoginState);

  const store = useStore();
  const router = useRouter();
  const loginMutation = useCustomMutation<Login, LoginVariables>(loginGql);
  const logoutMutation = useCustomMutation<Logout>(logoutGql);

  const { loginWithExternalWallet, walletLoading } = useExternalWallet();

  const resetLoginState = () => {
    Object.assign(loginState, {
      user: clone(emptyLoginState.user),
      twoFactorRequired: false,
    });
  };

  const commonAuthenticationHandler = async ({
    user,
    authToken,
    twoFactorRequired,
  }) => {
    if (!user || !user?.id || !authToken) {
      console.log({ user, authToken, twoFactorRequired });
      throw new Error("commonAuthenticationHandler failed");
    }

    if (user?.id && authToken?.token) {
      /**
       * Assign the new value to login state on
       * success login, Don't assign the auth token
       */
      Object.assign(loginState, { user, twoFactorRequired });

      if (user.pendingAction && user.pendingAction != UserPendingAction.A_) {
        // redirect to onboard if there's a pending action
        registrationSimpleStore.pendingAction = user.pendingAction;
        registrationSimpleStore.stakingId = user?.username;
        router.push(aliasRouteRegisterOnBoarding);
      }

      await store.dispatch("authUpdate", {
        isAuthenticated: false,
        token: authToken?.token,
        expiry: authToken?.expiry,
        userId: user?.id,
        stakingKeyHash: user?.wallet?.stakingKeyHash,
        avatar40: user?.avatar40,
        avatar80: user?.avatar80,
        avatar400: user?.avatar400,
      });

      await store.dispatch("loginSuccess", user);
    }
  };

  /**
   * ### LOGIN ###
   */
  const handleLogin = async ({
    username,
    password,
    doNotExpireSession,
  }: LoginInput) => {
    const loginResponse = await loginMutation.mutate({
      input: {
        username,
        password,
        doNotExpireSession,
        deviceId: getOrCreateDeviceId(),
      },
    });

    const parsedResponse = parseGqlResponse<Login_login_Authentication>(
      "Authentication",
      loginResponse
    );
    console.log("parsedResponse", parsedResponse);

    const user = parsedResponse?.data?.user;
    const authToken = parsedResponse.data?.authToken;
    const twoFactorRequired = parsedResponse.data?.twoFactorRequired;

    /**
     * Properly throw error when there's an error from endpoint
     */
    if (!isEmpty(parsedResponse.error?.errors) || !loginResponse) {
      throw new Error("Failed to login");
    }

    await commonAuthenticationHandler({ user, authToken, twoFactorRequired });
  };
  /**
   * ### END LOGIN ###
   */

  /**
   * ### LOGIN 2FA ###
   */
  const authenticate2faMutation = useCustomMutation<
    Authenticate2fa,
    Authenticate2faVariables
  >(authenticate2faGql);
  const handleLogin2faValidate = async ({ otpCode }) => {
    const authenticate2faResponse = await authenticate2faMutation.mutate({
      otpCode,
    });

    const parsedResponse =
      parseGqlResponse<Authenticate2fa_authenticate2fa_User>(
        "User",
        authenticate2faResponse
      );
    console.log("authenticate2faResponse: parsedResponse", parsedResponse);

    /**
     * Properly throw error when there's an error from endpoint
     */
    if (!isEmpty(parsedResponse.error?.errors) || !authenticate2faResponse) {
      throw new Error("Failed to login");
    }

    // 2fa setup success, set isAuthenticated to true
    await store.dispatch("authUpdate", {
      isAuthenticated: true,
    });
  };
  /**
   * ### LOGIN 2FA END ###
   */

  /**
   * ### LOGIN EXTERNAL ###
   */
  const handleLoginExternal = async (walletType, doNotExpireSession) => {
    const authResponse = await loginWithExternalWallet(
      walletType,
      doNotExpireSession
    );

    await commonAuthenticationHandler({
      user: authResponse?.user,
      authToken: authResponse?.authToken,
      twoFactorRequired: authResponse?.twoFactorRequired,
    });
  };
  /**
   * ### LOGIN EXTERNAL END ###
   */

  /**
   * ### LOGOUT ###
   */
  const handleLogout = async () => {
    const logoutResponse = await logoutMutation.mutate();

    const parsedResponse = parseGqlResponse<Logout_logout_GenericSuccess>(
      "GenericSuccess",
      logoutResponse
    );

    console.log("parsedResponse", parsedResponse);

    /**
     * TODO: Handle other errors
     */
    if (parsedResponse.data?.success) {
      await store.dispatch("logoutSuccess");
      await router.push({ name: routeNames.login });
    }
  };
  /**
   * ### LOGOUT END ###
   */

  /**
   * Experimental method to update user login state manually, this
   * should not be overused and only exists because external wallet
   * can login from register page, and 2fa should still be handled
   */
  const experimentalLoginStateUpdate = async ({
    twoFactorRequired = false,
  }) => {
    if (twoFactorRequired) {
      Object.assign(loginState, { twoFactorRequired });
      return loginState;
    }
  };

  return {
    loginState,
    resetLoginState,
    handleLogin,
    loginLoading: computed(() => loginMutation.loading.value),
    handleLogin2faValidate,
    twoFactorValidateLoading: computed(
      () => authenticate2faMutation.loading.value
    ),
    handleLoginExternal,
    walletLoading,
    handleLogout,
    logoutLoading: computed(() => logoutMutation.loading.value),
    experimentalLoginStateUpdate,
  };
}
