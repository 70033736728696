import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-inline" }
const _hoisted_2 = { class: "flex flex-1 self-start items-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", {
    class: "multi-account-selector-item p-4",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-account-select', _ctx.user)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_avatar, {
          size: "small",
          src: _ctx.user.avatar40 || _ctx.defaultAvatarSrc,
          class: "mr-2"
        }, null, 8, ["src"]),
        _createVNode(_component_a_typography_paragraph, {
          class: "wallet-address inline",
          copyable: _ctx.addressCopyable ? { text: _ctx.stakingKeyHash } : false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.maskWalletAddress(_ctx.stakingKeyHash)), 1)
          ]),
          _: 1
        }, 8, ["copyable"])
      ]),
      (_ctx.showIcon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.ArrowIcon,
            alt: "arrow",
            class: "ml-6 multi-account-selector-item__arrow",
            style: _normalizeStyle({
          transform: 'rotate(90deg)',
        })
          }, null, 12, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.userBalance)
      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
          key: 0,
          class: "balance my-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.userBalance), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}