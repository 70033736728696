import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes from "@/shared/router";
import sharedRouteNames from "@/shared/router/routeNames";
import sharedRoutePaths from "@/shared/router/routePaths";
import {
  webRouterAfterEachGuard,
  webRouterBeforeEachGuard,
} from "@/web/router";

export const oauthRoutes = {
  root: {
    name: "oauth",
    path: "/oauth",
  },
  authorize: {
    name: "oauth-authorize",
    path: "/authorize",
  },
  oauth2: {
    name: "oauth2",
    path: "/oauth2",
  },
};
/**
 * Note: Add `oauth` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. oauthHome)
 */

export const oauthRouteRecord: Array<RouteRecordRaw> = [
  {
    path: sharedRoutePaths.home,
    name: sharedRouteNames.home,
    redirect: oauthRoutes.authorize.path,
    meta: { requiresAuth: true },
  },
  {
    name: oauthRoutes.authorize.name,
    path: oauthRoutes.authorize.path,
    meta: { requiresAuth: true, authRedirectName: sharedRouteNames.login },
    component: () =>
      import(
        /* webpackChunkName: "oauthAuthorize" */ "../views/Authorize/Authorize.vue"
      ),
  },
  ...sharedRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // @see https://stackoverflow.com/a/57212309 this will ensure user will see top most of the
  // screen when navigating to different pages
  routes: oauthRouteRecord,
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(webRouterBeforeEachGuard);
router.afterEach(webRouterAfterEachGuard);

export default router;
