import gql from "graphql-tag";

export const initiateAuthenticationViaExternalWalletGql = gql`
  mutation InitiateAuthenticationViaExternalWallet(
    $input: InitiateAuthenticationViaExternalWalletInput!
  ) {
    initiateAuthenticationViaExternalWallet(input: $input) {
      ... on InitiateAuthenticationViaExternalWalletResponseDetail {
        nonce
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
