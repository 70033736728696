
import { defineComponent } from "vue";
import brandLogoSrc from "@/assets/logo.svg";
import brandSimpleLogoSrc from "@/assets/avatarada.svg";

export default defineComponent({
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 18,
    },
  },
  setup() {
    return {
      brandLogoSrc,
      brandSimpleLogoSrc,
    };
  },
  methods: {},
  components: {},
});
