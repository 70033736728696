import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  style: {"height":"42px"},
  class: "flex items-center"
}
const _hoisted_2 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_SidebarDrawer = _resolveComponent("SidebarDrawer")!
  const _component_AuthenticatedLayoutMobileHeader = _resolveComponent("AuthenticatedLayoutMobileHeader")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass({ 'authenticated-layout': true, 'p-6': _ctx.collapsed })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        collapsed: _ctx.collapsed,
        "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event)),
        width: "21rem",
        breakpoint: "sm",
        "collapsed-width": "0",
        theme: "light",
        collapsible: false,
        trigger: null,
        style: { height: '100vh', left: 0 }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PageHeader, {
            class: "pl-10",
            developer: _ctx.developer
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_BrandLogo, { height: 24 })
              ]),
              (_ctx.developer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.developer)
                      ? (_openBlock(), _createBlock(_component_a_alert, {
                          key: 0,
                          type: "success",
                          message: _ctx.t('Developer'),
                          class: "py-0 px-2",
                          banner: "",
                          "show-icon": false
                        }, null, 8, ["message"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["developer"]),
          _createVNode(_component_Sidebar, { developer: _ctx.developer }, null, 8, ["developer"])
        ]),
        _: 1
      }, 8, ["collapsed"]),
      _createVNode(_component_SidebarDrawer, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event))
      }, null, 8, ["visible"]),
      _createVNode(_component_a_layout, {
        class: _normalizeClass({ 'authenticated-main-layout': true, 'px-6': !_ctx.collapsed })
      }, {
        default: _withCtx(() => [
          (_ctx.collapsed)
            ? (_openBlock(), _createBlock(_component_AuthenticatedLayoutMobileHeader, {
                key: 0,
                developer: _ctx.developer,
                onOnDrawerOpen: _cache[2] || (_cache[2] = () => (_ctx.visible = true))
              }, null, 8, ["developer"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["class"]))
}