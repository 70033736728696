/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AccountRecoveryAttemptToRecover {
  PASSWORD = "PASSWORD",
  TWO_FACTOR_AUTH = "TWO_FACTOR_AUTH",
}

/**
 * An enumeration.
 */
export enum ApplicationPendingStep {
  A_ = "A_",
  STEP_1 = "STEP_1",
  STEP_2 = "STEP_2",
  STEP_3 = "STEP_3",
}

export enum ChannelType {
  ALL = "ALL",
  DOMAIN = "DOMAIN",
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
  SOCIAL = "SOCIAL",
}

/**
 * An enumeration.
 */
export enum DomainChannelVerificationMethod {
  A_ = "A_",
  FILE = "FILE",
  TXT = "TXT",
}

/**
 * An enumeration.
 */
export enum ExternalWalletType {
  CCVAULT = "CCVAULT",
  NAMI = "NAMI",
  NONE = "NONE",
  YOROI = "YOROI",
}

/**
 * An enumeration.
 */
export enum NotificationNotificationType {
  CALLBACK = "CALLBACK",
  EMAIL = "EMAIL",
  SMS = "SMS",
}

/**
 * An enumeration.
 */
export enum NotificationPurpose {
  DATA = "DATA",
  PROMOTION = "PROMOTION",
  TRANSACTION = "TRANSACTION",
}

/**
 * An enumeration.
 */
export enum NotificationStatus {
  FAILED = "FAILED",
  NO_DESTINATION = "NO_DESTINATION",
  PENDING = "PENDING",
  SENT = "SENT",
}

/**
 * An enumeration.
 */
export enum Provider {
  DISCORD = "DISCORD",
  GOOGLE = "GOOGLE",
  INSTAGRAM = "INSTAGRAM",
  SLACK = "SLACK",
  TELEGRAM = "TELEGRAM",
  TWITTER = "TWITTER",
}

/**
 * An enumeration.
 */
export enum SocialChannelProvider {
  DISCORD = "DISCORD",
  GOOGLE = "GOOGLE",
  INSTAGRAM = "INSTAGRAM",
  SLACK = "SLACK",
  TELEGRAM = "TELEGRAM",
  TWITTER = "TWITTER",
}

/**
 * An enumeration.
 */
export enum ToRecover {
  PASSWORD = "PASSWORD",
  TWO_FACTOR_AUTH = "TWO_FACTOR_AUTH",
}

/**
 * An enumeration.
 */
export enum UserPendingAction {
  A_ = "A_",
  PROFILE_SETUP = "PROFILE_SETUP",
  TWO_FACTOR_AUTH_SETUP = "TWO_FACTOR_AUTH_SETUP",
  WALLET_CONFIRMATION = "WALLET_CONFIRMATION",
}

/**
 * An enumeration.
 */
export enum UserRegistrationMethod {
  EXTERNAL = "EXTERNAL",
  USERPASS = "USERPASS",
}

/**
 * An enumeration.
 */
export enum VerificationMethod {
  FILE = "FILE",
  NONE = "NONE",
  TXT = "TXT",
}

export interface ApplicationCreateInput {
  name: string;
  authorizedDomainChannelIds?: (string | null)[] | null;
  includeSubdomain?: boolean | null;
  supportEmailChannelId?: string | null;
}

export interface ApplicationDetailsInput {
  clientId: string;
  redirectUri: string;
}

export interface ApplicationUpdateInput {
  id: string;
  name?: string | null;
  redirectUris?: (string | null)[] | null;
  authorizedDomainChannelIds?: (string | null)[] | null;
  includeSubdomain?: boolean | null;
  supportEmailChannelId?: string | null;
  category?: string | null;
  isPublic?: boolean | null;
  homepageUri?: string | null;
  tosUri?: string | null;
  privacyPolicyUri?: string | null;
}

export interface AuthorizeInput {
  clientId: string;
  redirectUri: string;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface ChannelApplicationsInput {
  channelId: number;
}

export interface CommunicationChannelFiltersInput {
  isPublic?: boolean | null;
  isVerified?: boolean | null;
  channelType?: ChannelType | null;
}

export interface CompleteAuthenticationViaExternalWalletInput {
  walletType: ExternalWalletType;
  signedMessage: string;
  signKey: string;
  doNotExpireSession?: boolean | null;
  deviceId?: string | null;
}

export interface ConfirmWalletInput {
  walletConfirmationAttemptId: string;
  recoveryUuid?: any | null;
}

export interface CreateDomainChannelInput {
  value: string;
  isPrimary?: boolean | null;
  callbackUrl?: string | null;
}

export interface CreateEmailChannelInput {
  email: string;
  isPrimary?: boolean | null;
}

export interface CreateMobileChannelInput {
  mobile: string;
  isPrimary?: boolean | null;
}

export interface CreateSocialChannelInput {
  authorizationCode: string;
  provider: Provider;
  authorizationVerifier?: string | null;
  authorizationSecret?: string | null;
  extraDetail?: any | null;
  redirectUrl?: string | null;
}

export interface DeleteDomainChannelInput {
  domainChannelId: string;
}

export interface DeleteEmailChannelInput {
  emailChannelId: string;
}

export interface DeleteMobileChannelInput {
  mobileChannelId: string;
}

export interface DeleteSocialChannelInput {
  socialChannelId: string;
}

export interface GetStakingKeyHashFromAddressInput {
  address: string;
}

export interface IdInput {
  id: string;
}

export interface InitiateAccountRecoveryInput {
  stakingKeyHash: string;
  toRecover: ToRecover;
}

export interface InitiateAuthenticationViaExternalWalletInput {
  walletType: ExternalWalletType;
  stakingKeyHash: string;
}

export interface LoginInput {
  username: string;
  password: string;
  doNotExpireSession?: boolean | null;
  deviceId?: string | null;
}

export interface PaginationInput {
  after?: string | null;
  pageSize: number;
}

export interface ProfileSetUpInput {
  name?: string | null;
}

export interface ProfileUpdateInput {
  name?: string | null;
  deleteAvatar?: boolean | null;
}

export interface PublicProfileInput {
  stakingKeyHash: string;
}

export interface RegisterInput {
  stakingKeyHash: string;
  password: string;
  doNotExpireSession?: boolean | null;
  deviceId?: string | null;
}

export interface ResendEmailChannelInput {
  emailChannelId: string;
}

export interface ResendMobileChannelInput {
  mobileChannelId: string;
}

export interface SetPasswordInput {
  recoveryUuid: any;
  newPassword: string;
}

export interface SetUp2FAInput {
  otpCode: string;
}

export interface TerminateOauthSessionInput {
  sessionId: string;
}

export interface TerminateSessionInput {
  authSessionId?: string | null;
}

export interface UpdateChannelInput {
  channelId: string;
  isPublic: boolean;
}

export interface UpdateDomainChannelInput {
  domainChannelId: string;
  isPrimary?: boolean | null;
  value?: string | null;
  callbackUrl?: string | null;
}

export interface UpdateEmailChannelInput {
  emailChannelId: string;
  isPrimary?: boolean | null;
  value?: string | null;
}

export interface UpdateMobileChannelInput {
  mobileChannelId: string;
  isPrimary?: boolean | null;
  value?: string | null;
}

export interface UpdateNotificationInput {
  notificationIdList?: (string | null)[] | null;
  markRead?: boolean | null;
  markSpam?: boolean | null;
}

export interface UpdateOauthSessionInput {
  sessionId: string;
  allowedNotificationTypes?: (string | null)[] | null;
  enableNotification?: boolean | null;
  notificationChannelId?: string | null;
  dataChannelId?: string | null;
}

export interface VerifyDomainChannelInput {
  domainChannelId: string;
  verificationMethod: VerificationMethod;
}

export interface VerifyEmailChannelInput {
  emailChannelId: string;
  verificationCode: string;
}

export interface VerifyMobileChannelInput {
  mobileChannelId: string;
  verificationCode: string;
}

export interface WalletConfirmationDetailInput {
  recoveryUuid?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
