
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import MultiAccountSelectorItem from "@/shared/components/MultiAccountSelector/MultiAccountSelectorItem.vue";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import routeNames from "@/shared/router/routeNames";
import { AUTH_ACTIONS, UserAccount } from "@/web/store/authStore";
import { useProfile } from "@/shared/composables/Profile/useProfile";
import isEmpty from "lodash/isEmpty";

export enum MultiAccountSelectorMode {
  normal = "normal",
  external = "external",
  login_selection = "login_selection",
}

export default defineComponent({
  emits: ["on-account-select"],
  props: {
    mode: {
      type: String as PropType<MultiAccountSelectorMode>,
      default: MultiAccountSelectorMode.normal,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { handleLogout, logoutLoading } = useAuthentication();
    const {
      isAuthenticated,
      selectedUser,
      previousSelectedUser,
      accountListNoSelected,
    } = useAccount();

    // used in login_section mode where multi-account selector is threated like a dropdown input only
    const localSelectedUser = reactive<UserAccount>(previousSelectedUser.value);

    watchEffect(() => {
      console.log("on-account-select", localSelectedUser);
      emit("on-account-select", localSelectedUser);
    });

    const { refetchProfileDetails, profileDetails, profileDetailsLoading } =
      useProfile(props.mode !== MultiAccountSelectorMode.login_selection);

    watch(profileDetails, (newProfileDetails) => {
      store.dispatch("userUpdate", newProfileDetails);

      /**
       * Also update account on the list except the token value
       */
      store.dispatch(AUTH_ACTIONS.UPDATE_ACCOUNT_ON_LIST, {
        userId: newProfileDetails?.id,
        stakingKeyHash: newProfileDetails?.wallet?.stakingKeyHash,
        avatar40: newProfileDetails?.avatar40,
        avatar80: newProfileDetails?.avatar80,
        avatar400: newProfileDetails?.avatar400,
      });
    });

    const handleAccountSelect = async (user: UserAccount) => {
      if (props.mode === MultiAccountSelectorMode.login_selection) {
        // login page will handle setting state
        Object.assign(localSelectedUser, user);
        return;
      }

      await store.dispatch("authUpdate", user);
      await refetchProfileDetails();

      /**
       * Reloading is required so endpoint calls will be called with new user token
       */
      if (props.mode === MultiAccountSelectorMode.normal) {
        window.location.reload();
      }
    };

    const handleAddAccount = async () => {
      // NOTE: It is important that this dispatch will go before login redirect
      await store.dispatch(AUTH_ACTIONS.ADD_ACCOUNT);

      /**
       * Add next url so it will redirect back to previous url properly
       */
      await router.push({
        name: routeNames.login,
        query: { next: router.currentRoute.value.fullPath },
      });
    };

    return {
      t,
      selectedUser,
      // use local selected user if mode is login_selection
      currentUser: computed(() =>
        props.mode === MultiAccountSelectorMode.login_selection
          ? localSelectedUser
          : selectedUser.value
      ),
      // only hide dropdown icon pn account selection page if there's a single value
      isSingleValue: computed(
        () =>
          props.mode === MultiAccountSelectorMode.login_selection &&
          accountListNoSelected.value.length === 1
      ),
      accountListNoSelected,
      isAuthenticated,
      handleLogout,
      logoutLoading,
      handleAccountSelect,
      handleAddAccount,
      MultiAccountSelectorMode,
      profileDetailsLoading,
    };
  },
  components: { MultiAccountSelectorItem },
  methods: { isEmpty },
});
