import { compactObject } from "@/shared/utils/object";
import { actionMutationFactory } from "@/shared/utils/storeUtils";
import { RootState } from "@/web/store";
import pick from "lodash/pick";

export enum AUTH_ACTIONS {
  ADD_ACCOUNT = "AUTH_ADD_ACCOUNT",
  SELECT_ACCOUNT = "AUTH_SELECT_ACCOUNT ",
  SWITCH_ACCOUNT = "AUTH_SWITCH_ACCOUNT",
  SWITCH_PREV_ACCOUNT = "AUTH_SWITCH_PREV_ACCOUNT",
  UPDATE_ACCOUNT_ON_LIST = "AUTH_UPDATE_ACCOUNT_ON_LIST",
}

export enum AuthActionEnum {
  SELECT = "SELECT",
  ADD = "ADD",
}

export type UserAccount = {
  userId: string | number;
  token: string;
  stakingKeyHash: string;
  avatar40?: string;
  avatar80?: string;
  avatar400?: string;
};

export type AuthState = {
  isAuthenticated: boolean;
  token: string;
  expiry: string;
  userId: string | number;
  prevUserId: string | number;
  list: UserAccount[];
  authAction: AuthActionEnum | null;
};

export type AuthPayload = {
  isAuthenticated: boolean;
  token: string;
  expiry: string;
  userId: string | number;
  prevUserId: string | number;
  stakingKeyHash: string;
  avatar40?: string;
  avatar80?: string;
  avatar400?: string;
};

type SwitchAccountPayload = {
  userId: string | number;
};

const authInitialState = {
  isAuthenticated: false,
  token: "",
  expiry: "",
  userId: "",
  prevUserId: "",
  list: [],
  authAction: null,
};

const authUpdateActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>("authUpdate", (state, payload) => {
  if (payload.isAuthenticated) state.isAuthenticated = payload.isAuthenticated;
  if (payload.token) state.token = payload.token;
  if (payload.expiry) state.expiry = payload.expiry;
  if (payload.userId) state.userId = payload.userId;
  if (payload.prevUserId) state.prevUserId = payload.prevUserId;

  /**
   * Check if account already on the list and required account values are complete
   */
  const account = pick(payload, [
    "userId",
    "token",
    "stakingKeyHash",
    "avatar40",
    "avatar80",
    "avatar400",
  ]);
  if (!account.userId || !account.token || !account.stakingKeyHash) {
    return;
  }
  const payloadIndex = state.list.findIndex(
    (item) => item.userId === payload.userId
  );
  // -1 means not part of the list
  if (payloadIndex < 0) {
    state.list.push(account);
  } else {
    state.list[payloadIndex] = account;
  }
});

const commonAuthActionHandler = (
  state,
  authAction: AuthState["authAction"] = null
) => {
  // this can toggle between add and select authAction, that's why we need to fallback to prevUserId
  let prevUserId = state.userId || state.prevUserId;

  if (!prevUserId && state.list[0] && state.list[0].userId) {
    // Add fallback value if userId and prevUserId is empty with the first user on the list,
    console.warn(
      "Unknown issue: prevUserId is empty, falling back to user value ",
      state.list[0]
    );
    prevUserId = state.list[0].userId;
  }

  Object.assign(state, {
    ...authInitialState,
    list: [...state.list],
    prevUserId,
    authAction,
  });
};

/**
 * ADD_ACCOUNT
 *
 * Call this on "Add Account" or "Add another account" action
 * Reset to initial state except for the list of account
 */
const authAddAccountActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>(AUTH_ACTIONS.ADD_ACCOUNT, (state) => {
  commonAuthActionHandler(state, AuthActionEnum.ADD);
});

/**
 * SELECT_ACCOUNT
 */
const authSelectAccountActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>(AUTH_ACTIONS.SELECT_ACCOUNT, (state) => {
  let authActionToPass = AuthActionEnum.SELECT;
  if (!state.list[0]) {
    // force add if there is not available account to select from
    authActionToPass = AuthActionEnum.ADD;
  }
  commonAuthActionHandler(state, authActionToPass);
});

const commonAccountSelectHandler = (state, userId) => {
  // get user based on new user id then set that user as the currently logged in user
  let newAuthenticatedUser = state.list.find((item) => item.userId === userId);

  // if for some reason it wasn't able to find the user, select the first available user
  // so the user will not be stuck on login page
  if (!newAuthenticatedUser && state.list[0]) {
    console.warn("Unknown issue: newAuthenticatedUser is empty", {
      newAuthenticatedUser,
    });
    newAuthenticatedUser = state.list[0];
  }

  Object.assign(state, {
    ...authInitialState,
    list: [...state.list],
    token: newAuthenticatedUser?.token,
    userId: newAuthenticatedUser?.userId,
    isAuthenticated: true,
  });
};

/**
 * SWITCH_ACCOUNT
 */
const authSwitchAccountActionMutation = actionMutationFactory<
  AuthState,
  SwitchAccountPayload,
  RootState
>(AUTH_ACTIONS.SWITCH_ACCOUNT, (state, payload) => {
  commonAccountSelectHandler(state, payload.userId);
});

/**
 * SWITCH_PREV_ACCOUNT
 */
const authSwitchPrevAccountActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>(AUTH_ACTIONS.SWITCH_PREV_ACCOUNT, (state) => {
  commonAccountSelectHandler(state, state.prevUserId);
});

/**
 * UPDATE_ACCOUNT_ON_LIST
 *
 * Call this when receiving new profile information from backend
 * So the account list is always updated
 */
const authUpdateAccountOnListActionMutation = actionMutationFactory<
  AuthState,
  UserAccount,
  RootState
>(AUTH_ACTIONS.UPDATE_ACCOUNT_ON_LIST, (state, payload) => {
  const list = state.list;
  const payloadIndex = list.findIndex((item) => item.userId === payload.userId);

  // -1 means not part of the list
  if (payloadIndex > -1) {
    state.list[payloadIndex] = {
      // combine old and new state with payload value striped of null/undefined values
      ...list[payloadIndex],
      ...compactObject(payload),
    };
  }
});

const authLogoutActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>("logoutSuccess", (state) => {
  /**
   * copy current userId to be removed from auth list
   * create a clone of state.list, then remove current userId with filter
   */
  const userIdToLogout = state.userId;
  const list = [...state.list].filter((item) => item.userId != userIdToLogout);

  Object.assign(state, {
    ...authInitialState,
    list,
  });
});

export const authStore = {
  state: {
    ...authInitialState,
  },
  actions: {
    ...authLogoutActionMutation.actions,
    ...authUpdateActionMutation.actions,
    ...authAddAccountActionMutation.actions,
    ...authSelectAccountActionMutation.actions,
    ...authSwitchAccountActionMutation.actions,
    ...authSwitchPrevAccountActionMutation.actions,
    ...authUpdateAccountOnListActionMutation.actions,
  },
  mutations: {
    ...authLogoutActionMutation.mutations,
    ...authUpdateActionMutation.mutations,
    ...authAddAccountActionMutation.mutations,
    ...authSelectAccountActionMutation.mutations,
    ...authSwitchAccountActionMutation.mutations,
    ...authSwitchPrevAccountActionMutation.mutations,
    ...authUpdateAccountOnListActionMutation.mutations,
  },
};
