import gql from "graphql-tag";

export const profileGql = gql`
  query Profile {
    profile {
      ... on User {
        id
        username
        created
        modified
        wallet {
          id
          created
          modified
          stakingKeyHash
          mainAddress
        }
        name
        avatar40
        avatar80
        avatar400
        pendingAction
        registrationMethod
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
