
import { computed, defineComponent, PropType } from "vue";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { UserAccount } from "@/web/store/authStore";
import { User } from "@/web/store/userInfo";
import defaultAvatarSrc from "@/assets/profile/default-avatar.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";

export default defineComponent({
  emits: ["on-account-select"],
  props: {
    user: {
      type: Object as PropType<UserAccount & User>,
      required: true,
    },
    addressCopyable: {
      type: Boolean,
      default: false,
    },
    showIcon: Boolean,
  },
  setup(props) {
    return {
      defaultAvatarSrc,
      stakingKeyHash: computed(
        () =>
          props.user?.stakingKeyHash || props.user?.wallet?.stakingKeyHash || ""
      ),
      userBalance: "", // TODO: integrate balance endpoint,
      ArrowIcon,
    };
  },
  methods: {
    maskWalletAddress,
  },
  components: {},
});
