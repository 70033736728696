<template>
  <a-layout class="non-authenticated-layout">
    <slot />
  </a-layout>
</template>
<style lang="less">
@import "../../../theme/breakpoints";

.non-authenticated-layout {
  width: fit-content;
  margin: auto;
  min-width: 26.25rem;

  @media @desktop-up {
    max-width: 75vw;
  }

  @media @desktop {
    max-width: 85vw;
  }

  @media @laptop {
    max-width: 85vw;
  }

  @media @tablet-down {
    max-width: 100%;
    min-width: unset;
    padding: 0 1.5rem 1.5rem;
  }

  @media @mobile-down {
    width: 100%;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
</style>
<script>
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    return { t };
  },
});
</script>
