import first from "lodash/first";
import take from "lodash/take";
import takeRight from "lodash/takeRight";

export const removeSpaces = (input: string): string => {
  return input ? input.replace(/\s/g, "") : "";
};

export const maskWalletAddress = (walletAddress: string) => {
  const startStr = take(walletAddress, 6).join("");
  const endStr = takeRight(walletAddress, 5).join("");

  return `${startStr}....${endStr}`;
};

/**
 * Remove first slash it finds from str parameter
 *
 * @param str
 * @returns
 *
 * @example:
 * removeSlash("/app")
 * => "app"
 */
export const removeSlash = (str: string) => str.replace(/\//, "");

/**
 * Just like removeSlash but this will just remove if slash is start of string
 */
export const removeLeadingSlash = (str: string) => str.replace(/^\//, "");

/**
 * Just like removeSlash but this will just remove if slash is end of string
 */
export const removeTrailingSlash = (str: string) => str.replace(/\/$/, "");

export const getFirsts = (words?: string, delimiter = " ", charsLimit = 2) => {
  if (!words) return;

  return words
    .split(delimiter)
    .map((word) => first(word))
    .join("")
    .substring(0, charsLimit);
};
