import gql from "graphql-tag";

export const authenticate2faGql = gql`
  mutation Authenticate2fa($otpCode: String!) {
    authenticate2fa(otpCode: $otpCode) {
      ... on User {
        id
        username
        created
        wallet {
          id
          stakingKeyHash
        }
        name
        avatar40
        avatar80
        avatar400
        pendingAction
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
