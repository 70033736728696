import { Buffer } from "buffer";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import filter from "lodash/filter";
import { makeToast } from "@/shared/utils/toast";
import { ExternalWalletType } from "../../../__generated__/globalTypes";
import { i18nTranslate } from "@/plugins/i18n";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _window: any = window;

/**
 * Always updated with the backend enums
 * Note that there are some enums that shouldn't be part of supported wallets
 */
export const SUPPORTED_EXTERNAL_WALLETS = [
  ExternalWalletType.CCVAULT,
  ExternalWalletType.NAMI,
  ExternalWalletType.YOROI,
].map((walletType) => walletType.toLowerCase());

export const SUPPORTED_EXTERNAL_WALLETS_OPTIONS = [
  {
    label: "Eternl",
    value: ExternalWalletType.CCVAULT,
  },
  {
    label: "Nami",
    value: ExternalWalletType.NAMI,
  },
  {
    label: "Yoroi",
    value: ExternalWalletType.YOROI,
  },
];

const jsonToHex = (json) => {
  return Buffer.from(JSON.stringify(json), "utf8").toString("hex");
};

/**
 * Check if wallet is already connected
 * Most wallet specific methods requires to enabled first via enable()
 *
 * @param walletName
 * @returns boolean if wallet is connected
 */
const isWalletConnected = async (walletName): Promise<boolean> => {
  return await _window.cardano[walletName].isEnabled();
};

const cardanoGetStakingKeyHash = async ({ walletName }) => {
  if (!isWalletConnected(walletName)) {
    throw new Error(
      "Failed to get wallet staking key hash: Wallet not connected"
    );
  }

  const fullApi = _window.cardanoApi[walletName];

  /**
   * a little bit hacky to get first address
   * and removing first 2 characters on rewardAddr
   */
  const rewardAddress = await fullApi.getRewardAddresses();
  const rewardAddr = String(first(rewardAddress) || "");
  return rewardAddr.substring(2, rewardAddr.length);
};

/**
 * This will prompt the user to sign data
 * @param param0
 * @returns
 */
export const cardanoGetSignatureAndKey = async ({
  walletName,
  nonce,
  stakingKeyHash,
}) => {
  walletName = walletName.toLowerCase(); // all saved keys are in lowercase

  if (!isWalletConnected(walletName)) {
    throw new Error("Failed to get signature and key: Wallet not connected");
  }

  const fullApi = _window.cardanoApi[walletName];
  let addresses = await fullApi.getUsedAddresses();

  if (isEmpty(addresses)) {
    addresses = await fullApi.getUnusedAddresses();
    console.warn(
      `${walletName} getUsedAddresses() is empty, fallback to getUnusedAddresses()`
    );
  }

  /**
   * a little bit hacky to get first address
   */
  const usedAddress = first(addresses) || "";

  const jsonPayload = { staking_key_hash: stakingKeyHash, nonce };
  const payloadHex = jsonToHex(jsonPayload);

  const signedData = await fullApi.signData(usedAddress, payloadHex);

  return {
    ...signedData,
    stakingKeyHash,
    usedAddress,
  };
};

const registerCardanoApi = (walletName, fullApi) => {
  return new Promise((resolve, reject) => {
    if (!walletName || isEmpty(fullApi)) {
      reject({
        message: "Make sure all required parameters are provided",
        walletName,
        fullApi,
      });
    }
    /**
     * Register cardano APIs on window
     */
    _window.cardanoApi = {
      ...(_window.cardanoApi || {}),
      [walletName]: fullApi,
    };
    resolve(_window.cardanoApi);
  });
};

/**
 * This will trigger wallet prompt and returns staking_key_hash if the user approves
 *
 * @param walletName
 * @returns stakingKeyHash
 */
export const cardanoInitWallet = (
  walletNameRaw: ExternalWalletType
): Promise<string> => {
  // the enum is in caps but the DApps are low cased
  const walletName = walletNameRaw.toLowerCase();

  if (!_window.cardano[walletName]) {
    makeToast(
      "warning",
      i18nTranslate("Wallet not found"),
      i18nTranslate(
        "Please make sure that the wallet is installed in your browser!"
      )
    );
  }

  return new Promise((resolve, reject) => {
    _window.cardano[walletName]
      .enable({ requestIdentification: true })
      .then(async (api) => {
        await registerCardanoApi(walletName, api);
        const stakingKeyHash = cardanoGetStakingKeyHash({ walletName });
        resolve(stakingKeyHash);
      }, reject);
  });
};

/**
 * Call this on-mount to get list of connected wallets
 */
export const loadCardanoWallets = async () => {
  if (typeof _window.cardano === "undefined") {
    console.log("Cardano API not found");
  } else {
    /**
     * Available wallet DApp connectors on browser
     */
    const availableWallets = filter(
      SUPPORTED_EXTERNAL_WALLETS,
      (walletName) => typeof _window.cardano[walletName] != "undefined"
    );

    const connectedWallet: any = [];

    for (const wallet of availableWallets) {
      const enabled = await _window.cardano[wallet].isEnabled();
      connectedWallet.push({
        wallet,
        enabled,
      });
    }

    console.log(connectedWallet);

    // TODO: check other wallets too
    _window.cardano.yoroi
      .enable({ requestIdentification: true, onlySilent: true })
      .then(
        (api) => {
          console.log("successful silent reconnection", api);
          // TODO: update handling here to get friendly wallet name if we will support it
          // FIXME: experimental cardano{wallet name}.experimental is not a standard feature, onlySilent also not supported on all wallets
        },
        (err) => {
          if (String(err).includes("onlySilent:fail")) {
            console.log("no silent re-connection available");
          } else {
            console.error(
              "Silent reconnection failed for unknown reason!",
              err
            );
          }
        }
      );
  }
};
