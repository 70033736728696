import sharedRoutePaths from "@/shared/router/routePaths";

export default {
  ...sharedRoutePaths,
  forgotPassword: "/forgot-password",
  profile: "/profile",
  publicProfile: "/profile/:staking_key_hash",
  sessions: "/sessions",
  inbox: "/inbox/:senderId?",
  settings: "/settings",
  settingsTwoFactorAuthentication: "/setup/2fa/:action?",
  communicationChannels: "/communication-channels",
  backupCode: "/backup-code",
  accountDeleted: "/account-deleted",
  applications: "/applications",
  applicationsAdd: "/applications/add/:step",
  applicationsConfig: "/applications/configure/:app_id",
};
