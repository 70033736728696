import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"height":"4.5rem"},
  class: "flex items-center justify-center -mt-6"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['-mt-0.5', !_ctx.developer && 'flex-1'])
    }, [
      _createVNode(_component_BrandLogo, {
        iconOnly: "",
        height: 18
      })
    ], 2),
    (_ctx.developer)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_alert, {
              type: "success",
              message: _ctx.t('Developer mode'),
              class: "py-0 px-2 ml-2",
              banner: "",
              "show-icon": false
            }, null, 8, ["message"])
          ]),
          _createVNode(_component_a_button, {
            class: "ml-auto",
            type: "default",
            size: "small",
            onClick: _ctx.handleSidebarMode
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("To profile ")) + " ", 1),
              _createElementVNode("img", {
                src: _ctx.ArrowIcon,
                class: "ml-2",
                alt: "arrow"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 64))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "flex items-center cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-drawer-open')))
        }, [
          _createVNode(_component_a_typography_title, {
            level: 4,
            class: "inline-block mb-0 mr-4 font-normal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Menu")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_MenuOutlined, {
            class: "trigger",
            style: {"font-size":"24px"}
          })
        ]))
  ]))
}