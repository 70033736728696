import { Register_register_Authentication_user } from "@/api/onboarding/__generated__/Register";
import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/web/store";
import merge from "lodash/merge";

type UserWallet = {
  id: string;
  stakingKeyHash: string;
};

export type User = {
  id: string;
  username: string;
  created: string;
  wallet: UserWallet | null;
  name: string;
  avatar40: string | null;
  avatar80: string | null;
  avatar400: string | null;
  pendingAction: string | null;
};

export type UserInfoState = Partial<User>;

const userInfoInitialState = {
  id: "",
  username: "",
  created: "any",
  wallet: {
    id: "",
    stakingKeyHash: "",
  },
  name: "",
  avatar40: "",
  avatar80: "",
  avatar400: "",
  pendingAction: "",
  registrationMethod: "",
};

export type UserInfoPayload = Register_register_Authentication_user;

const loginSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("loginSuccess", (state, payload) => {
  // MOCK, will move this to graphql cache
  state = merge(state, payload);
});

const userUpdateActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("userUpdate", (state, payload) => {
  /**
   * merge state value with the new one from payload
   * in this case, payload value is prioritize
   */
  // state.user = merge(state.user, payload);
  state = merge(state, payload);
});

const logoutSuccessActionMutation = resetStateActionMutationFactory<
  UserInfoState,
  RootState
>("logoutSuccess", userInfoInitialState);

export const userInfo = {
  state: {
    ...userInfoInitialState,
  },
  actions: {
    ...loginSuccessActionMutation.actions,
    ...logoutSuccessActionMutation.actions,
    ...userUpdateActionMutation.actions,
  },
  mutations: {
    ...loginSuccessActionMutation.mutations,
    ...logoutSuccessActionMutation.mutations,
    ...userUpdateActionMutation.mutations,
  },
};
