import gql from "graphql-tag";

export const loginGql = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ... on Authentication {
        user {
          id
          username
          created
          wallet {
            id
            stakingKeyHash
          }
          name
          avatar40
          avatar80
          avatar400
          pendingAction
        }
        authToken {
          token
          expiry
        }
        twoFactorRequired
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
