import { computed } from "vue";
import { useStore } from "vuex";
import filter from "lodash/filter";
import omit from "lodash/omit";
import { UserAccount } from "@/web/store/authStore";
import { UserRegistrationMethod } from "../../../../__generated__/globalTypes";

/**
 * Reusable composable for Profile updates
 *
 * @param
 * @returns
 */
export function useAccount() {
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
  /**
   * It is partially authenticated if auth token is present in the store
   * We will need this check for the register page and login onboarding page
   */
  const isPartialAuthenticated = computed(
    () => store.state.auth.token && !store.state.auth.isAuthenticated
  );
  const selectedUserId = computed(() => store.state.auth.userId);
  const selectedUserStakingKeyHash = computed(
    () => store.state.userInfo.wallet.stakingKeyHash
  );
  const selectedUser = computed(() => ({
    ...store.state?.userInfo,
    ...omit(store.state?.auth, "list"),
  }));
  const accountList = computed(() => store.state?.auth?.list);

  /**
   * Value to be used to show previous user information
   * e.g. login page selection
   */
  const previousSelectedUser = computed(() => {
    // if there is no first user it means that the list is empty
    if (!store.state.auth.list[0]) return {};

    // return previous user or first user as fallback
    return (
      store.state.auth.list.find(
        (account) => account.userId === store.state.auth.prevUserId
      ) ?? store.state.auth.list[0]
    );
  });

  const accountListNoSelected = computed<UserAccount[]>(() =>
    filter(
      accountList.value,
      (account) => String(account.userId) !== String(selectedUserId.value)
    )
  );

  const isExternalRegistration = computed(() => {
    return (
      store.state.userInfo.registrationMethod ===
      UserRegistrationMethod.EXTERNAL
    );
  });

  return {
    isAuthenticated,
    isPartialAuthenticated,
    selectedUserId,
    selectedUserStakingKeyHash,
    selectedUser,
    previousSelectedUser,
    accountList,
    accountListNoSelected,
    isExternalRegistration,
  };
}
