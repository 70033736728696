import defaultTo from "lodash/defaultTo";
import toNumber from "lodash/toNumber";
import { removeLeadingSlash, removeTrailingSlash } from "./stringHelper";

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";
const logRocketId = process.env.VUE_APP_LOG_ROCKET_ID || "";

const avataradaBaseUrl = process.env.VUE_APP_AVATARADA_BASE_URL || "";
const baseUrlPath = process.env.VUE_APP_BASE_URL_PATH || "";

/**
 * @example
 * [removeTrailingSlash("avatarada.io/"), removeLeadingSlash("/app")].join("/")
 * => avatarada.io/app
 */
const basePublicUrlPath = [
  removeTrailingSlash(avataradaBaseUrl),
  removeLeadingSlash(baseUrlPath),
]
  .join("/") // join with slash
  .replaceAll("//", "/"); // normalize path if there are double slash

export const config = {
  avataradaBaseUrl,
  baseUrlPath,
  basePublicUrlPath,
  documentationBaseUrl:
    process.env.VUE_APP_DOCUMENTATION_BASE_URL || "https://docs.avatarada.io",
  dataNotifApiDocLink:
    process.env.VUE_APP_DATA_NOTIF_API_DOC_LINK || "https://docs.avatarada.io",

  allowAutomatedTestMode:
    process.env.VUE_APP_ALLOW_AUTOMATED_TEST_MODE === "true",
  httpGqlServerUrl: process.env.VUE_APP_HTTP_GQL_SERVER_URL || "",
  websocketGqlServerUrl: process.env.VUE_APP_WEBSOCKET_GQL_SERVER_URL || "",
  merchantBrand: process.env.VUE_APP_MERCHANT_BRAND || "",
  contactEmail: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_EMAIL || "",
  // allowed interval for resending verification code
  resendVerificationCodeIntervalMs: toNumber(
    defaultTo(process.env.VUE_APP_RESEND_VERIFICATION_CODE_INTERVAL_MS, 60000)
  ),
  deviceIdCookieName: process.env.VUE_APP_DEVICE_ID_COOKIE_NAME || "deviceid",

  /**
   * LOCALIZATION AND NUMBER FORMATS
   * for number format convert to number, .env returns string
   */
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  minimumFractionDigits: toNumber(
    defaultTo(process.env.VUE_APP_NUMBER_FORMAT_MINIMUM_FRACTION, 0)
  ),
  maximumFractionDigits: toNumber(
    process.env.VUE_APP_NUMBER_FORMAT_MAXIMUM_FRACTION || 2
  ),

  /**
   * Authenticator Download URL
   */
  authenticatorAppStoreURL: process.env.VUE_APP_APPSTORE_URL || "",
  authenticatorGooglePlayURL: process.env.VUE_APP_GOOGLEPLAY_URL || "",

  /**
   * reCAPTCHA
   */
  recaptchaSiteKey,
  isCaptchaEnabled: !!recaptchaSiteKey,

  /**
   * Logrocket
   *
   * have to check against string "true" because .env uses string type
   * only allow logrocket on development/test environment
   */
  logRocketId,
  logRocketEnabled:
    process.env.VUE_APP_LOG_ROCKET_ENABLE === "true" && !!logRocketId,

  /** static links */
  contactUrl: process.env.VUE_APP_CONTACT_URL,
  privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
  cookiePolicyUrl: process.env.VUE_APP_COOKIE_POLICY_URL,
  termsWebsiteUseUrl: process.env.VUE_APP_TERMS_WEBSITE_USE_URL,
  learnMoreDomainUrl: process.env.VUE_APP_LEARN_MORE_DOMAIN_URL || "#",

  /**
   * social/3rd party authentication
   */
  authBaseUrl: process.env.VUE_APP_AUTH_BASE_URL || "",
  developerBaseUrl: process.env.VUE_APP_DEVELOPER_APP_BASE_URL || "",
  authTelegramBotId: process.env.VUE_APP_AUTH_TELEGRAM_BOT_ID,
  authTelegramBotUser: process.env.VUE_APP_AUTH_TELEGRAM_BOT_USER,
  authTelegramDataAuthUrl: process.env.VUE_APP_AUTH_TELEGRAM_DATA_AUTH_URL,
  authTelegramAuthOrigin: process.env.VUE_APP_AUTH_TELEGRAM_AUTH_ORIGIN,

  authInstagramRedirectUri: process.env.VUE_APP_AUTH_INSTAGRAM_REDIRECT_URI,
  authInstagramClientId: process.env.VUE_APP_AUTH_INSTAGRAM_CLIENT_ID,
  authTwitterRequestTokenUri:
    process.env.VUE_APP_AUTH_TWITTER_REQUEST_TOKEN_URI,
  authTwitterClientId: process.env.VUE_APP_AUTH_TWITTER_CLIENT_ID,
  authTwitterClientSecret: process.env.VUE_APP_AUTH_TWITTER_CLIENT_SECRET,
  authTwitterRedirectUri: process.env.VUE_APP_AUTH_TWITTER_REDIRECT_URI,
  authDiscordClientId: process.env.VUE_APP_AUTH_DISCORD_CLIENT_ID,
  authDiscordRedirectUri: process.env.VUE_APP_AUTH_DISCORD_REDIRECT_URI,
  authSlackClientId: process.env.VUE_APP_AUTH_SLACK_CLIENT_ID,
  authSlackRedirectUri: process.env.VUE_APP_AUTH_SLACK_REDIRECT_URI,
  authGoogleClientId: process.env.VUE_APP_AUTH_GOOGLE_CLIENT_ID,
  authGoogleClientSecret: process.env.VUE_APP_AUTH_GOOGLE_CLIENT_SECRET,
  authGoogleRedirectUri: process.env.VUE_APP_AUTH_GOOGLE_REDIRECT_URI,
};

// Update once we need to export individual widgets/component
export const isFullSite = true;
