import { RouteRecordRaw } from "vue-router";
import { LayoutTypes } from "@/shared/utils/enums/layouts";

/* eslint-disable */
// @ts-ignore
import devToolsRoutes from "./devToolsRoutes";
import routeNames from "./routeNames";
import routePaths, { routeAlias } from "./routePaths";
/* eslint-enable */

/**
 * Note: Add `shared` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. sharedHome)
 */
const sharedRoutes: Array<RouteRecordRaw> = [
  ...devToolsRoutes,
  {
    path: routePaths.login,
    name: routeNames.login,
    component: () =>
      import(
        /* webpackChunkName: "webLogin" */ "../../web/views/Login/Login.vue"
      ),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      layout: LayoutTypes.nonAuthenticatedLayout,
    },
  },
  {
    path: routePaths.register,
    name: routeNames.register,
    component: () =>
      import(
        /* webpackChunkName: "webRegister" */ "../../web/views/Register/Register.vue"
      ),
    meta: { requiresAuth: false },
    alias: routeAlias.register,
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "shared404" */ "../views/404.vue"),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      layout: LayoutTypes.nonAuthenticatedLayout,
    },
  },
];

export default sharedRoutes;
