import gql from "graphql-tag";

export const setUpProfileGql = gql`
  mutation SetupProfile($input: ProfileSetUpInput!, $avatar: Upload) {
    setUpProfile(input: $input, avatar: $avatar) {
      ... on User {
        id
        username
        wallet {
          id
          stakingKeyHash
        }
        name
        avatar40
        avatar80
        avatar400
        pendingAction
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
