import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes from "@/shared/router";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import store from "../store";
import NProgress from "nprogress";
import { LayoutTypes } from "@/shared/utils/enums/layouts";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { UserPendingAction } from "./../../../__generated__/globalTypes";
import { aliasRouteRegisterOnBoarding } from "@/shared/router/routePaths";
import { oauthRoutes } from "@/oauth/router";
import { config } from "@/shared/utils/config";
import {
  removeLeadingSlash,
  removeTrailingSlash,
} from "@/shared/utils/stringHelper";
import { AuthActionEnum, AUTH_ACTIONS } from "@/web/store/authStore";

/**
 * Note: Add `web` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. webHome)
 */
const webRoutes: Array<RouteRecordRaw> = [
  ...sharedRoutes,
  {
    path: routePaths.home,
    name: routeNames.home,
    redirect: { name: routeNames.inbox },
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.forgotPassword,
    name: routeNames.forgotPassword,
    component: () =>
      import(
        /* webpackChunkName: "webForgotPassword" */ "../views/ForgotPassword/ForgotPassword.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: routePaths.backupCode,
    name: routeNames.backupCode,
    component: () =>
      import(/* webpackChunkName: "webBackupCode" */ "../views/BackupCode.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: routePaths.profile,
    name: routeNames.profile,
    component: () =>
      import(
        /* webpackChunkName: "webProfile" */ "../views/Profile/Profile.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.publicProfile,
    name: routeNames.publicProfile,
    component: () =>
      import(
        /* webpackChunkName: "webPublicProfile" */ "../views/PublicProfile/PublicProfile.vue"
      ),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      layout: LayoutTypes.nonAuthenticatedLayout,
    },
  },
  {
    path: routePaths.communicationChannels,
    name: routeNames.communicationChannels,
    component: () =>
      import(
        /* webpackChunkName: "webCommunicationChannels" */ "../views/CommunicationChannels/CommunicationChannels.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.sessions,
    name: routeNames.sessions,
    component: () =>
      import(
        /* webpackChunkName: "webSessions" */ "../views/Sessions/Sessions.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.inbox,
    name: routeNames.inbox,
    component: () =>
      import(/* webpackChunkName: "webInbox" */ "../views/Inbox/Inbox.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.settings,
    name: routeNames.settings,
    component: () =>
      import(
        /* webpackChunkName: "webSettings" */ "../views/Settings/Settings.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.settingsTwoFactorAuthentication,
    name: routeNames.settingsTwoFactorAuthentication,
    component: () =>
      import(
        /* webpackChunkName: "webSetupTwoFactorAuthentication" */ "../views/Setup/SetupTwoFactorAuthentication.vue"
      ),
    meta: { requiresAuth: true, layout: LayoutTypes.nonAuthenticatedLayout }, // alternatively we can declare what layout to use
  },
  {
    path: routePaths.accountDeleted,
    name: routeNames.accountDeleted,
    component: () =>
      import(
        /* webpackChunkName: "webAccountDeleted" */ "../views/AccountDeleted.vue"
      ),
    meta: { requiresAuth: false },
  },
  /**
   * Expose oauth widget on web for now.
   * Register oauthRoutes directly
   */
  {
    name: oauthRoutes.oauth2.name,
    path: oauthRoutes.oauth2.path,
    component: () =>
      import(
        /* webpackChunkName: "webOauth2" */ "../../oauth/views/Authorize/Authorize.vue"
      ),
    meta: { requiresAuth: true, layout: LayoutTypes.nonAuthenticatedLayout },
  },
  {
    path: routePaths.applications,
    name: routeNames.applications,
    component: () =>
      import(
        /* webpackChunkName: "webApplications" */ "../views/Developer/Dashboard/Dashboard.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.applicationsAdd,
    name: routeNames.applicationsAdd,
    component: () =>
      import(
        /* webpackChunkName: "webApplications" */ "../views/Developer/AddApp/AddApp.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.applicationsConfig,
    name: routeNames.applicationsConfig,
    component: () =>
      import(
        /* webpackChunkName: "webApplications" */ "../views/Developer/Configure/ConfigureApp.vue"
      ),
    meta: { requiresAuth: true },
  },
];

/**
 * Remove leading and trailing slash first then combine with slash to normalize url
 */
const baseUrl = [
  removeTrailingSlash(String(process.env.BASE_URL)),
  removeLeadingSlash(config.baseUrlPath),
].join("/");

const router = createRouter({
  history: createWebHistory(baseUrl),
  // @see https://stackoverflow.com/a/57212309 this will ensure user will see top most of the
  // screen when navigating to different pages
  routes: webRoutes,
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

/**
 * Export this method to be able to use in other application e.g. Developer and OAuth
 */
export const webRouterBeforeEachGuard = (to, from, next) => {
  // Start the route progress bar.
  NProgress.start();
  const isAuthenticated = store.state.auth.isAuthenticated;
  const pendingAction = store.state.userInfo.pendingAction;
  const meta = to.meta;

  if (
    pendingAction &&
    pendingAction != UserPendingAction.A_ &&
    to.name !== routeNames.register
  ) {
    //pendingAction is not equals to None, route should not be register to avoid infinite redirect
    registrationSimpleStore.pendingAction = <UserPendingAction>pendingAction;
    return next(aliasRouteRegisterOnBoarding);
  }
  /**
   * Special screen that shares session with the whole app but doesn't require specific state
   */
  if (meta.sharedAuthSession) {
    /**
     * Check meta description if it requires to be authenticated and redirect name path is set
     */
    if (meta.requiresAuth && !isAuthenticated) {
      if (meta.authRedirectName) {
        return next({ name: <string>meta.authRedirectName });
      } else {
        console.error(
          "authRedirectName is not set for route that requires authentication",
          meta
        );
      }
    }

    return next();
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    /**
     * this route requires auth, check if logged in if not, redirect to login page.
     * add next query url for the automatic redirect after successful login
     */
    return next({ name: routeNames.login, query: { next: to.fullPath } });
  }

  // If going to a page that does not require authentication
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      to.name === routeNames.login &&
      store.state.auth.authAction !== AuthActionEnum.ADD
    ) {
      // dispatch select account if AuthActionEnum Add is not yet selected
      store.dispatch(AUTH_ACTIONS.SELECT_ACCOUNT);
    }
    /**
     * Some pages (like Register page) should not be accessed if user is logged in
     * Except if the page is allowed even authenticated with meta.allowedAuth
     */
    if (
      isAuthenticated &&
      Object.prototype.hasOwnProperty.call(meta, "requiresAuth") &&
      !meta.requiresAuth &&
      !meta.allowedAuth &&
      pendingAction == UserPendingAction.A_
    ) {
      return next({ name: routeNames.home });
    }
    return next();
  }

  next();
};

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
export const webRouterAfterEachGuard = () => {
  // Complete the animation of the route progress bar.
  NProgress.done();
};

router.beforeEach(webRouterBeforeEachGuard);

router.afterEach(webRouterAfterEachGuard);

export default router;
