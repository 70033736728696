
import AuthenticatedLayout from "@/shared/components/Layouts/AuthenticatedLayout.vue";
import NonAuthenticatedLayout from "@/shared/components/Layouts/NonAuthenticatedLayout.vue";
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import VErrorBoundary from "vue-error-boundary";
import { useStore } from "vuex";
import isEmpty from "lodash/isEmpty";
import { LayoutTypes } from "@/shared/utils/enums/layouts";
import { UserPendingAction } from "./../../__generated__/globalTypes";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  components: { AuthenticatedLayout, NonAuthenticatedLayout, VErrorBoundary },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
    const sharedAuthSession = computed(() => !!route?.meta?.sharedAuthSession);

    /**
     * EXPERIMENTAL DELAY RENDER
     * Check if the route.name or route.meta is available
     *
     * Note:  This can be implemented using computed hooks but for good measure we will add a timeout
     *        of 3 seconds that will render regardless if the route.name or route.meta is available
     *
     * These values won't be available on initial mount which causes the
     * layout to change from non-authenticated to authenticated or reverse and vice versa
     *
     * almost all routes have a name except for 404, but since it has meta value use it instead
     */
    const ready = ref(false);

    watchEffect(() => {
      ready.value = !!route.name || !isEmpty(route.meta);
    });

    onMounted(() => {
      // on timeout render regardless of route values
      setTimeout(() => (ready.value = true), 3000);
    });

    // END EXPERIMENTAL DELAY RENDER

    /**
     * Based developer mode based on root application url
     */
    const isDeveloperPage = computed(() =>
      route.name?.toString().startsWith(routeNames.applications)
    );

    /**
     * Check if route's meta has layout props
     */
    const isUseNonAuthenticatedLayout = computed(
      () => route?.meta?.layout === LayoutTypes.nonAuthenticatedLayout
    );
    /**
     * Check if user has pendingAction
     */
    const userHasPendingAction = computed(
      () =>
        store.state.userInfo.pendingAction != UserPendingAction.A_ &&
        store.state.userInfo.pendingAction !== ""
    );

    return {
      t,
      ready,
      isAuthenticated,
      sharedAuthSession,
      isUseNonAuthenticatedLayout,
      userInfoLoading: false,
      handleError(err) {
        // TODO: create submit to API
        console.log("submit_to_api", err);
      },
      userHasPendingAction,
      isDeveloperPage,
    };
  },
});
