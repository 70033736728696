import routeNames from "@/web/router/routeNames";
import { useI18n } from "vue-i18n";

export function useSidebar() {
  const { t } = useI18n();

  return {
    sidebarRoutes: [
      {
        text: t("Inbox"),
        route: { name: routeNames.inbox },
        // badge: 0, // TODO: integrate actual new inbox message
      },
      {
        text: t("Sessions"),
        route: { name: routeNames.sessions },
      },
      {
        text: t("Communication Channels"),
        route: { name: routeNames.communicationChannels },
      },
      {
        text: t("Profile"),
        route: { name: routeNames.profile },
      },
      {
        text: t("Settings"),
        route: { name: routeNames.settings },
      },
    ],
  };
}
