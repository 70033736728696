export const isEmbeddedMode = process.env.VUE_APP_EMBEDDED_MODE === "true";

/**
 * Select current application router automatically
 */
let router;
if (isEmbeddedMode) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  router = require("@/oauth/router").default;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  router = require("@/web/router").default;
}

export default router;
