import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    placement: "left",
    closable: "",
    visible: _ctx.localVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localVisible) = $event)),
    bodyStyle: { height: 'calc(100% - 72px)' },
    class: "sidebar-drawer"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_BrandLogo, { height: 18 })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Sidebar, {
        onRouteChanged: _cache[0] || (_cache[0] = () => (_ctx.localVisible = false))
      })
    ]),
    _: 1
  }, 8, ["visible", "bodyStyle"]))
}