import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import isEmpty from "lodash/isEmpty";
import {
  cardanoGetSignatureAndKey,
  cardanoInitWallet,
} from "@/shared/utils/cardano";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import {
  ExternalWalletType,
  UserPendingAction,
} from "../../../../__generated__/globalTypes";
import routeNames from "@/shared/router/routeNames";
import useCustomMutation from "@/api/graphqlClient/useCustomMutation";
import { apiErrorCodes } from "@/shared/utils/constants";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { initiateAuthenticationViaExternalWalletGql } from "@/api/wallet/initiateAuthenticationViaExternalWallet";
import { completeAuthenticationViaExternalWalletGql } from "@/api/wallet/completeAuthenticationViaExternalWallet";
import {
  InitiateAuthenticationViaExternalWalletVariables,
  InitiateAuthenticationViaExternalWallet,
  InitiateAuthenticationViaExternalWallet_initiateAuthenticationViaExternalWallet_InitiateAuthenticationViaExternalWalletResponseDetail,
} from "@/api/wallet/__generated__/InitiateAuthenticationViaExternalWallet";
import {
  CompleteAuthenticationViaExternalWallet,
  CompleteAuthenticationViaExternalWalletVariables,
  CompleteAuthenticationViaExternalWallet_completeAuthenticationViaExternalWallet_Authentication,
} from "@/api/wallet/__generated__/CompleteAuthenticationViaExternalWallet";
import { getOrCreateDeviceId } from "@/shared/utils/browser";

export const useExternalWallet = () => {
  const walletLoading = ref("");
  const store = useStore();
  const router = useRouter();
  const nextUrl = router.currentRoute.value.query?.next as string;

  const initiateAuthenticationViaExternalWalletMutation = useCustomMutation<
    InitiateAuthenticationViaExternalWallet,
    InitiateAuthenticationViaExternalWalletVariables
  >(initiateAuthenticationViaExternalWalletGql);

  const completeAuthenticationViaExternalWalletMutation = useCustomMutation<
    CompleteAuthenticationViaExternalWallet,
    CompleteAuthenticationViaExternalWalletVariables
  >(completeAuthenticationViaExternalWalletGql);

  /**
   * Initiate AuthenticationVia External Wallet
   */
  const initAuthentication = async (walletType: ExternalWalletType) => {
    // Init wallet authorization
    const stakingKeyHash = await cardanoInitWallet(walletType);

    // Init external wallet authentication
    const initResponse =
      await initiateAuthenticationViaExternalWalletMutation.mutate({
        input: {
          walletType,
          stakingKeyHash,
        },
      });

    const parsedInitResponse =
      parseGqlResponse<InitiateAuthenticationViaExternalWallet_initiateAuthenticationViaExternalWallet_InitiateAuthenticationViaExternalWalletResponseDetail>(
        "InitiateAuthenticationViaExternalWalletResponseDetail",
        initResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("parsedInitResponse", parsedInitResponse);

    if (!isEmpty(parsedInitResponse.error?.errors) || !initResponse) {
      throw new Error("Failed to init external wallet auth");
    }

    return { stakingKeyHash, ...parsedInitResponse.data };
  };

  const continueAuthentication = async ({
    walletType,
    nonce,
    stakingKeyHash,
    doNotExpireSession,
  }) => {
    const signedData = await cardanoGetSignatureAndKey({
      walletName: walletType,
      nonce,
      stakingKeyHash,
    });

    /**
     * signedMessage = signature
     * signKey = key
     */
    const authResponse =
      await completeAuthenticationViaExternalWalletMutation.mutate({
        input: {
          walletType,
          signedMessage: signedData.signature,
          signKey: signedData.key,
          doNotExpireSession,
          deviceId: getOrCreateDeviceId(),
        },
      });

    const parsedAuthResponse =
      parseGqlResponse<CompleteAuthenticationViaExternalWallet_completeAuthenticationViaExternalWallet_Authentication>(
        "Authentication",
        authResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("parsedAuthResponse", parsedAuthResponse);

    if (!isEmpty(parsedAuthResponse.error?.errors) || !authResponse) {
      throw new Error("Failed to complete external wallet auth");
    }

    return parsedAuthResponse.data;
  };

  /**
   * Reusable combination  of init and complete authentication via external wallet
   * @returns authData
   */
  const handleCommonInitAndContinue = async (
    walletType: ExternalWalletType,
    doNotExpireSession: boolean
  ) => {
    const initData = await initAuthentication(walletType);

    // complete external wallet authentication with backend
    const authData = await continueAuthentication({
      walletType,
      nonce: initData?.nonce,
      stakingKeyHash: initData.stakingKeyHash,
      doNotExpireSession,
    });

    return authData;
  };

  const loginWithExternalWallet = async (
    walletType: ExternalWalletType,
    doNotExpireSession: boolean
  ) => {
    try {
      walletLoading.value = walletType;
      const authData = await handleCommonInitAndContinue(
        walletType,
        doNotExpireSession
      );
      return authData;
    } catch (error) {
      console.log("loginWithExternalWallet failed", error);
    } finally {
      walletLoading.value = "";
    }
  };

  const registerWithExternalWallet = async (
    walletType: ExternalWalletType,
    doNotExpireSession: boolean
  ) => {
    try {
      walletLoading.value = walletType;

      const authData = await handleCommonInitAndContinue(
        walletType,
        doNotExpireSession
      );

      const user = authData?.user;
      const authToken = authData?.authToken;

      /**
       * If user id is exist, it means that the user is created
       */
      if (user?.id) {
        await store.dispatch("authUpdate", {
          // if there's no pending action and two factor is not required, it is fully authenticated
          isAuthenticated:
            user.pendingAction === UserPendingAction.A_ &&
            !authData?.twoFactorRequired,
          token: authToken?.token,
          expiry: authToken?.expiry,
          userId: user?.id,
          stakingKeyHash: user?.wallet?.stakingKeyHash,
          avatar40: user?.avatar40,
          avatar80: user?.avatar80,
          avatar400: user?.avatar400,
        });

        await store.dispatch("loginSuccess", user);

        /**
         * If there's no pending action, it means that the user is
         * already registered and can be redirected to home page
         */
        if (user.pendingAction === UserPendingAction.A_) {
          if (authData?.twoFactorRequired) {
            /**
             * If the user is already registered and 2fa is required
             * Return custom object so registration page can handle properly.
             *
             * This composable can't handle changing login and state that's why this is necessary
             */
            return {
              redirectToLogin: true,
              twoFactorRequired: authData?.twoFactorRequired,
            };
          } else {
            await router.push(nextUrl || { name: routeNames.home });
          }
        } else {
          // continue with registration
          registrationSimpleStore.stakingId =
            user?.wallet?.stakingKeyHash ?? user?.username;
          registrationSimpleStore.pendingAction = user?.pendingAction;
        }
      }
    } catch (error) {
      console.log("registerWithExternalWallet failed", error);
    } finally {
      walletLoading.value = "";
    }
  };

  return {
    registerWithExternalWallet,
    loginWithExternalWallet,
    walletLoading,
    loading: computed(() => !!walletLoading.value),
  };
};
