import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_ctx.title || _ctx.$slots['title'])
    ? (_openBlock(), _createBlock(_component_a_layout_header, {
        key: 0,
        class: _normalizeClass([
      'page-header flex justify-between',
      _ctx.developer ? 'developer-mode' : 'items-center',
    ])
      }, {
        default: _withCtx(() => [
          (_ctx.title)
            ? (_openBlock(), _createBlock(_component_a_typography_title, {
                key: 0,
                class: "page-title m-0",
                style: {"line-height":"42px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "title"),
          _renderSlot(_ctx.$slots, "header-action")
        ]),
        _: 3
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}