import sharedRouteNames from "@/shared/router/routeNames";

export default {
  ...sharedRouteNames,
  forgotPassword: "forgot-password",
  profile: "profile",
  publicProfile: "public-profile",
  sessions: "sessions",
  inbox: "inbox",
  settings: "settings",
  settingsTwoFactorAuthentication: "settings-2fa",
  communicationChannels: "communication-channels",
  backupCode: "backup-code",
  accountDeleted: "account-deleted",
  applications: "applications",
  applicationsAdd: "applications-add",
  applicationsConfig: "applications-config",
};
