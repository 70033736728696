export default {
  home: "/",
  login: "/login",
  register: "/register",
};

export const aliasRouteRegisterOnBoarding = "/onboarding";

export const routeAlias = {
  register: [aliasRouteRegisterOnBoarding],
};
