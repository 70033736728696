
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import NonAuthenticatedLayout from "@/shared/components/Layouts/NonAuthenticatedLayout.vue";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
  components: { NonAuthenticatedLayout },
});
