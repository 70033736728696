import { setContext } from "@apollo/client/link/context";
import store from "@/web/store";
import { getOrCreateDeviceId } from "@/shared/utils/browser";

export const getAuthToken = () => {
  const savedUserInfo = store?.state?.auth;

  if (savedUserInfo?.token) {
    return `Token ${savedUserInfo.token}`;
  }

  return "";
};

export const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // add device id if there's a token available
      ...(store?.state?.auth.token
        ? { "device-id": getOrCreateDeviceId() }
        : {}),
      authorization: getAuthToken(),
    },
  };
});
