import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";
import { UnwrapNestedRefs } from "@vue/reactivity";

/**
 * Other information will be saved in userInfo
 */

export type RegistrationData = {
  pendingAction: string | null;
  stakingId: string;
  amountForVerification: number;
};

export type RegistrationSimpleStore = UnwrapNestedRefs<
  RegistrationData & { resetStore: () => void }
>;

export const registrationSimpleStore: RegistrationSimpleStore =
  makeSimpleStore<RegistrationData>({
    pendingAction: "",
    stakingId: "",
    amountForVerification: 0,
  });
