import isPlainObject from "lodash/isPlainObject";
import size from "lodash/size";
import {
  GqlError,
  HideIfErrorCode,
  parseGqlError,
} from "@/shared/utils/graphql/errorHandler";

export type ParseGqlResponse<GqlSuccessType> = {
  data: GqlSuccessType | null;
  error: GqlError | null;
};

/**
 * Use this to parse response from graphql. It should return the response
 * without the wrapped data and document name.
 *
 * [Notes]
 *   - Make sure to use the generated type so you can still benefit from type-checks
 *   - Will return the error details if error was returned from the API. See [GqlSuccessType]
 *
 * [Example]
 * i.e. const parsedGqlResponse = parseGqlResponse<TokenAuthenticateType>("Authentication", tokenAuthenticateResponse);
 *
 * /// parsedGqlResponse
 * {name: 'pedro', email: pedro@abc.com}
 *
 * /// tokenAuthenticateResponse
 * data: {tokenAuthenticate: {name: 'pedro', email: pedro@abc.com}}}
 *
 */
export function parseGqlResponse<GqlSuccessType>(
  successTypeName: string,
  response,
  /* provide `hideIfErrorCode` to hide error toast on the specified API Error Code */
  hideIfErrorCode?: string | number | null | HideIfErrorCode
): ParseGqlResponse<GqlSuccessType> {
  const returnResponse: ParseGqlResponse<GqlSuccessType> = {
    data: null,
    error: null,
  };
  /**
   * Support both response wrapped on data and wrapped with the document name.
   * [Query] does not wrap the whole response on data, whereas [Mutation] still
   * wrap the response on data.
   *
   *   i.e.
   *   query - {tokenAuthenticate: {name: 'pedro', email: pedro@abc.com}}
   *   mutation - {data: {tokenAuthenticate: {name: 'pedro', email: pedro@abc.com}}}
   */
  if (
    (isPlainObject(response?.data) && size(response?.data) === 1) ||
    (isPlainObject(response) && size(response) === 1)
  ) {
    const responseInnerData = response?.data ?? response;

    for (const responseItem in responseInnerData) {
      // noinspection JSUnfilteredForInLoop
      const responseItemValue = responseInnerData[responseItem];

      if (responseItemValue?.__typename === successTypeName) {
        returnResponse.data = responseItemValue as GqlSuccessType;
      }

      if (responseItemValue?.__typename === "ResponseErrors") {
        returnResponse.error = parseGqlError(
          responseItemValue,
          hideIfErrorCode
        );
      }
    }
  }

  return returnResponse;
}
