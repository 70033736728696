import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sidebar flex flex-col" }
const _hoisted_2 = { class: "sidebar-top flex-1" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "sidebar__menu-item-text"
}
const _hoisted_5 = { class: "sidebar-bottom inline-flex items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiAccountSelector = _resolveComponent("MultiAccountSelector")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MultiAccountSelector),
      _createVNode(_component_a_menu, {
        mode: "inline",
        selectedKeys: _ctx.selectedKeys,
        "onUpdate:selectedKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedKeys) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (!_ctx.developer)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sidebarRoutes, (route) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: route.route,
                    class: "sidebar-menu-item m-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: route.route,
                        class: _normalizeClass([
                  'flex items-center',
                  _ctx.isCollapsed
                    ? 'sidebar-route-menu-item--collapsed'
                    : 'sidebar-route-menu-item',
                ]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('route-changed')))
                      }, {
                        default: _withCtx(() => [
                          (route.icon)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: route.icon
                              }, null, 8, _hoisted_3))
                            : _createCommentVNode("", true),
                          (!_ctx.isCollapsed)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.text), 1))
                            : _createCommentVNode("", true),
                          (route.badge)
                            ? (_openBlock(), _createBlock(_component_a_badge, {
                                key: 2,
                                count: route.badge,
                                class: "ml-1"
                              }, null, 8, ["count"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_ctx.developer)
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: 1,
                    class: "sidebar-menu-item m-0",
                    onClick: _cache[1] || (_cache[1] = () => _ctx.openInNewTab(_ctx.config.documentationBaseUrl))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Documentation")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["selectedKeys"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_a_button, {
        type: "default",
        size: "small",
        onClick: _ctx.handleSidebarMode
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.developer ? _ctx.t("Go to profile") : _ctx.t("For developers")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}