
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    developer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const collapsed = ref(false);

    return {
      collapsed,
    };
  },
  components: {},
});
