
import Sidebar from "@/shared/components/Sidebar/Sidebar.vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import PageHeader from "./PageHeader.vue";
import AuthenticatedLayoutMobileHeader from "./AuthenticatedLayoutMobileHeader.vue";
import BrandLogo from "@/shared/components/Layouts/BrandLogo.vue";
import SidebarDrawer from "@/shared/components/Sidebar/SidebarDrawer.vue";

export default defineComponent({
  components: {
    Sidebar,
    PageHeader,
    AuthenticatedLayoutMobileHeader,
    BrandLogo,
    SidebarDrawer,
  },
  props: {
    developer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    const collapsed = ref(false); // sidebar collapsed
    const visible = ref(false); // drawer visible

    return {
      t,
      collapsed,
      visible,
    };
  },
});
