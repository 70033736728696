import UAParser from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { config } from "@/shared/utils/config";

export const openInNewTab = (url: string | null | undefined) => {
  if (!window || !url) return;

  window.open(url, "_blank");
};

export const openInPopupWindow = (
  url,
  windowName = "_blank",
  w = 480,
  h = 680
) => {
  if (!window) return;

  const y = window.screenY + (window.outerHeight - h) / 2;
  const x = window.screenX + (window.outerWidth - w) / 2;
  return window.open(
    url,
    windowName,
    `toolbar=no, location=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );
};

export const downloadData = async (url, fileName) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Something went wrong when downloading data", error);
  }
};

/**
 * Create device id
 *
 * @example
 * createDeviceId()
 * => Chrome_103.0.0.0_Linux_x86_64_8e57d00c-f098-4711-adea-b7ec752b1756
 */
export const createDeviceId = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  const deviceId = [
    result.browser.name,
    result.browser.version,
    result.os.name,
    result.os.version,
    uuidv4(),
  ].join("_");
  return deviceId;
};

export const deleteDeviceId = () => {
  Cookies.remove(config.deviceIdCookieName);
};

export const getOrCreateDeviceId = () => {
  if (Cookies.get(config.deviceIdCookieName)) {
    return Cookies.get(config.deviceIdCookieName);
  } else {
    const deviceId = createDeviceId();
    Cookies.set(config.deviceIdCookieName, deviceId, { expires: 365 });
    return deviceId;
  }
};

/**
 * Timeout utility useful for async/await
 * @param delayMs
 * @returns
 */
export const sleep = (delayMs = 100) =>
  new Promise((resolve) => setTimeout(resolve, delayMs));
