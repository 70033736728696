import { computed } from "vue";
import { useStore } from "vuex";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { setUpProfileGql } from "@/api/onboarding/setUpProfile";
import {
  SetupProfile,
  SetupProfileVariables,
  SetupProfile_setUpProfile_User,
} from "@/api/onboarding/__generated__/SetupProfile";
import { updateProfileGql } from "@/api/profile/updateProfile";
import {
  UpdateProfile,
  UpdateProfileVariables,
  UpdateProfile_updateProfile_User,
} from "@/api/profile/__generated__/UpdateProfile";
import { apiErrorCodes } from "../../utils/constants";
import { parseGqlResponse } from "../../utils/graphql/responseParser";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import isEmpty from "lodash/isEmpty";
import { useQuery } from "@vue/apollo-composable";
import { profileGql } from "@/api/profile/profile";
import { Profile_profile_User } from "@/api/profile/__generated__/Profile";
import routeNames from "@/web/router/routeNames";
import { UserPendingAction } from "../../../../__generated__/globalTypes";

/**
 * Reusable composable for Profile updates
 *
 * @param
 * @returns
 */
export function useProfile(fetchProfileOnMount = false) {
  const { t } = useI18n();
  const router = useRouter();
  const store = useStore();

  /**
   * GET CURRENT PROFILE
   */
  const {
    result: profileResult,
    loading: profileDetailsLoading,
    refetch: refetchProfileDetails,
  } = useQuery(profileGql, {}, () => ({
    enabled: fetchProfileOnMount,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true, // default config doesn't seem to work, add notifyOnNetworkStatusChange explicitly
  }));
  const profileDetails = computed(() => {
    const parsedResponse = parseGqlResponse<Profile_profile_User>(
      "User",
      profileResult.value
    );

    return parsedResponse.data;
  });
  /**
   * END GET CURRENT PROFILE
   */

  /**
   * SETUP PROFILE
   */
  const setUpProfileMutation = useCustomMutation<
    SetupProfile,
    SetupProfileVariables
  >(setUpProfileGql);

  const handleProfileSetup = async ({ name, avatar }) => {
    // Setup Profile Mutation
    const registerResponse = await setUpProfileMutation.mutate({
      input: {
        name,
      },
      avatar,
    });

    // Parsed gql response
    const parsedResponse = parseGqlResponse<SetupProfile_setUpProfile_User>(
      "User",
      registerResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("parsedResponse", parsedResponse);

    const user = parsedResponse?.data;

    if (user?.id) {
      // update userInfo store
      await store.dispatch("userUpdate", {
        name: user?.name,
        avatar: user?.avatar40,
        pendingAction: user?.pendingAction,
      });

      registrationSimpleStore.pendingAction = user?.pendingAction;

      message.info(t("Profile setup successfully."));

      /**
       * Check if there's no pending action then redirect the user to the profile page
       */
      if (user.pendingAction === UserPendingAction.A_) {
        await router.push({ name: routeNames.profile });
      }
    }

    return user;
  };
  /**
   * END OF SETUP PROFILE
   */

  /** Update Profile */
  const updateProfileMutation = useCustomMutation<
    UpdateProfile,
    UpdateProfileVariables
  >(updateProfileGql);

  const handleUpdateProfile = async ({
    name,
    deleteAvatar = false,
    avatar,
  }) => {
    const updateProfileResponse = await updateProfileMutation.mutate({
      input: {
        name,
        deleteAvatar,
      },
      avatar,
    });

    // Parsed gql response
    const parsedResponse = parseGqlResponse<UpdateProfile_updateProfile_User>(
      "User",
      updateProfileResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("parsedResponse", parsedResponse);

    const user = parsedResponse?.data;

    if (!isEmpty(parsedResponse.error?.errors) || !updateProfileResponse) {
      throw new Error("Failed to update profile.");
    }

    if (user?.id) {
      // update userInfo store
      await store.dispatch("userUpdate", {
        name: user?.name,
        avatar40: user?.avatar40,
        avatar80: user?.avatar80,
        avatar400: user?.avatar400,
      });

      message.info(t("Profile updated successfully."));
    }

    return user;
  };

  return {
    refetchProfileDetails,
    profileDetails,
    profileDetailsLoading,
    handleProfileSetup,
    setupProfileLoading: computed(() => setUpProfileMutation.loading.value),
    handleUpdateProfile,
    updateProfileLoading: computed(() => updateProfileMutation.loading.value),
  };
}
