
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Sidebar from "@/shared/components/Sidebar/Sidebar.vue";
import BrandLogo from "@/shared/components/Layouts/BrandLogo.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const localVisible = useVModel(props, "visible");

    return { t, localVisible };
  },
  components: { Sidebar, BrandLogo },
});
