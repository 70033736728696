
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import BrandLogo from "@/shared/components/Layouts/BrandLogo.vue";
import ArrowIcon from "@/assets/icons/arrow.svg";
import { config } from "@/shared/utils/config";
import { openInNewTab } from "@/shared/utils/browser";

export default defineComponent({
  emits: ["on-drawer-open"],
  props: {
    developer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const collapsed = ref(false);
    const visible = ref(false);

    return {
      t,
      collapsed,
      visible,
      ArrowIcon,
      handleSidebarMode: () => {
        if (props.developer) {
          // on developer mode, go to profile
          openInNewTab(config.authBaseUrl);
        } else {
          // on profile mode, go to develoepr
          openInNewTab(config.developerBaseUrl);
        }
      },
    };
  },
  components: { BrandLogo },
});
