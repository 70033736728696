import { App } from "@vue/runtime-core";
import VueAuthenticate from "vue-authenticate-2";
import axios from "axios";
import map from "lodash/map";
import startCase from "lodash/startCase";
import { config } from "@/shared/utils/config";
import { i18nTranslate } from "./i18n";
import { Provider } from "../../__generated__/globalTypes";

export const providers = {
  [Provider.GOOGLE]: {
    name: Provider.GOOGLE,
    label: "Google",
    authorizationEndpoint: "https://accounts.google.com/o/oauth2/auth",
    oauthType: "2.0",
    scope: ["openid", "email"],
    scopeDelimiter: " ",
    clientId: config.authGoogleClientId,
    responseType: "code",
    redirectUri: config.authGoogleRedirectUri,
    defaultUrlParams: ["scope", "client_id", "response_type", "redirect_uri"],
    popupOptions: { width: 500, height: 640 },
  },
  /**
   * Create a bot on telegram to be able to receive token
   * See https://core.telegram.org/widgets/login on how create a login widget
   *
   * Note: This setting is not used in vue-authenticate-2 and have custom handling
   * see src/shared/composables/Social/useTelegramAuthProvider.ts for options
   */
  [Provider.TELEGRAM]: {
    name: Provider.TELEGRAM,
    label: "Telegram",
    authorizationEndpoint: "https://oauth.telegram.org/auth",
  },
  /**
   * Create instagram app
   * https://developers.facebook.com/docs/instagram-basic-display-api/getting-started/
   *
   * Add Instagram Tester
   * Facebook Developer Page > Roles > Instagram Testers
   */
  [Provider.INSTAGRAM]: {
    name: Provider.INSTAGRAM,
    label: "Instagram",
    authorizationEndpoint: "https://api.instagram.com/oauth/authorize",
    redirectUri: config.authInstagramRedirectUri,
    scope: ["user_profile"],
    scopeDelimiter: "+",
    oauthType: "2.0",
    clientId: config.authInstagramClientId,
    responseType: "code",
    requiredUrlParams: ["scope"],
    popupOptions: { width: 720, height: 720 },
  },
  [Provider.TWITTER]: {
    name: Provider.TWITTER,
    label: "Twitter",
    authorizationEndpoint: "https://api.twitter.com/oauth/authenticate",
    redirectUri: config.authTwitterRedirectUri, // this is required, without this the pop-up window will not close
    oauthType: "2.0",
    clientId: config.authTwitterClientId,
    clientSecret: config.authTwitterClientSecret,
    requiredUrlParams: ["oauth_token", "oauth_token_secret"],
    popupOptions: { width: 500, height: 640 },
  },
  /**
   * Check discord oauth 2 login documentation
   * https://discord.com/developers/docs/topics/oauth2
   */
  [Provider.DISCORD]: {
    name: Provider.DISCORD,
    label: "Discord",
    authorizationEndpoint: "https://discord.com/api/oauth2/authorize",
    oauthType: "2.0",
    clientId: config.authDiscordClientId,
    scope: ["identify"],
    responseType: "code",
    scopeDelimiter: ",",
    redirectUri: config.authDiscordRedirectUri,
    defaultUrlParams: ["response_type", "client_id", "redirect_uri", "scope"],
    popupOptions: { width: 640, height: 820 },
  },
  /**
   * Create slack app on https://api.slack.com/apps
   *
   * Add redirectUri
   * Update app User Token Scopes
   */
  [Provider.SLACK]: {
    name: Provider.SLACK,
    label: "Slack",
    authorizationEndpoint: "https://slack.com/oauth/authorize",
    oauthType: "2.0",
    clientId: config.authSlackClientId,
    scope: "identity.basic",
    redirectUri: config.authSlackRedirectUri,
    defaultUrlParams: ["client_id", "redirect_uri", "scope"],
    popupOptions: { width: 640, height: 720 },
  },
};

export const supportedSocialPlatformInformationMessages = {
  [Provider.INSTAGRAM]: i18nTranslate(
    "We will not be able to send messages through Instagram, but you able to add it to your public profile."
  ),
  [Provider.TWITTER]: i18nTranslate(
    "We will not be able to send messages through Twitter, but you able to add it to your public profile."
  ),
};

/**
 * Return supported platforms in name-label pair
 */
export const supportedSocialPlatforms = map(providers, (item, key) => ({
  name: key,
  label: item?.label || startCase(key), // fallback label to provider name
  info: supportedSocialPlatformInformationMessages[key] || "",
  value: "", // this is the value of the platform, e.g. username
}));

export const useVueAuthenticatePlugin = (app: App): App => {
  return app.use(VueAuthenticate, {
    baseUrl: config.authBaseUrl, // Your API domain
    axios: axios.create({}),
    providers,
  });
};
