import { ApolloLink } from "@apollo/client/core";
import { onError } from "apollo-link-error";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import store from "@/web/store";
import useLogging from "@/shared/composables/useLogging";
import { wsClientContainer } from "./wsLink";
import currentRouter from "@/shared/router/currentRouter";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import { UserPendingAction } from "./../../../__generated__/globalTypes";

/**
 * Update this for to include all [__typename] for all errors that we
 * need to logout the user. For available [__typename] for all errors refer to the schema
 */
const errorTypeNameToLogout = ["AuthenticationError"];
const errorTypeNameToOtp = [
  "OTPDeviceDoesNotExistError",
  "TwoFactorAuthenticationRequired",
];

export const customErrorHandlerLink = new ApolloLink((operation, forward) => {
  const { submitInfoToApi } = useLogging();
  return forward(operation).map((data) => {
    const parsedGqlResponse = parseGqlResponse<void>(
      "",
      data?.data,
      HideIfErrorCode.ALL_ERRORS
    );

    /**
     * Check if user is authenticated or token exists
     * Token exists even the user is not fully authenticated. e.g. during registration
     */
    if (
      !isEmpty(parsedGqlResponse?.error?.errors) &&
      (store?.state?.auth?.isAuthenticated || !!store.state.auth.token)
    ) {
      /// Automatic logout based on custom error
      const shouldLogout = parsedGqlResponse?.error?.errors?.some((error) =>
        errorTypeNameToLogout.includes(error?.__typename)
      );

      if (shouldLogout) {
        (async () => {
          // close ws client connection
          wsClientContainer.wsClient?.close(true, true);

          const fullPath = currentRouter.currentRoute?.value?.fullPath;

          await store.dispatch("logoutSuccess");
          await currentRouter.push({
            name: routeNames.login,
            query: { next: fullPath },
          });
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate("You have been logout due to invalid session")
          );
          submitInfoToApi(
            "SESSION_EXPIRED: Logged Out Automatically due to inactivity"
          );
        })();
      }

      // For OTPDeviceDoesNotExistError, to redirect to 2fa page if error encountered
      const errorOtp = parsedGqlResponse?.error?.errors?.some((error) =>
        errorTypeNameToOtp.includes(error?.__typename)
      );

      if (errorOtp) {
        (async () => {
          registrationSimpleStore.pendingAction =
            UserPendingAction.TWO_FACTOR_AUTH_SETUP;
          await currentRouter.push({
            name: routeNames.settingsTwoFactorAuthentication,
          });
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate(
              "OTP Device not exist. Enable two factor authentication to proceed."
            )
          );
        })();
      }
      // End OTPDeviceDoesNotExistError
    }

    return data;
  });
});

export const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    /**
     * Add console log, this will be available on logrocket
     */
    console.group("errorHandlerLink");
    console.log(`graphQLErrors`, !!graphQLErrors);
    console.log(`networkError`, !!networkError);
    console.log(`errorResponse`, { graphQLErrors, networkError, operation });
    console.groupEnd();

    if (graphQLErrors) {
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("Something went wrong. Please try again later.")
      );
    } else if (networkError) {
      /**
       * Do not proceed with createLog if there's a network error because it will just fail
       */
      if (operation.operationName === "createLog") {
        return;
      }

      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("A network error occurred, Kindly refresh and try again.")
      );
    }
  }
);
