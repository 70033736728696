import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "card-border-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiAccountSelectorItem = _resolveComponent("MultiAccountSelectorItem")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_ctx.isAuthenticated || _ctx.mode === _ctx.MultiAccountSelectorMode.login_selection)
    ? (_openBlock(), _createBlock(_component_a_dropdown, {
        key: 0,
        class: _normalizeClass([
      'multi-account-selector',
      !_ctx.isSingleValue && 'multi-account-selector--single',
    ]),
        trigger: "click",
        placement: "bottomCenter"
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            disabled: _ctx.$attrs.disabled || _ctx.profileDetailsLoading
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_spin, {
                tip: _ctx.t('Please wait'),
                spinning: _ctx.profileDetailsLoading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MultiAccountSelectorItem, {
                    user: _ctx.currentUser,
                    addressCopyable: _ctx.mode === _ctx.MultiAccountSelectorMode.normal,
                    showIcon: !_ctx.isSingleValue
                  }, null, 8, ["user", "addressCopyable", "showIcon"])
                ]),
                _: 1
              }, 8, ["tip", "spinning"])
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 2
      }, [
        (!_ctx.isSingleValue)
          ? {
              name: "overlay",
              fn: _withCtx(() => [
                _createVNode(_component_a_menu, { class: "multi-account-selector__menu" }, {
                  default: _withCtx(() => [
                    (!_ctx.isEmpty(_ctx.accountListNoSelected))
                      ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 0 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountListNoSelected, (account) => {
                              return (_openBlock(), _createBlock(_component_MultiAccountSelectorItem, {
                                user: account,
                                key: account.userId,
                                class: "cursor-pointer",
                                onOnAccountSelect: _ctx.handleAccountSelect
                              }, null, 8, ["user", "onOnAccountSelect"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.mode !== _ctx.MultiAccountSelectorMode.login_selection)
                      ? (_openBlock(), _createBlock(_component_a_menu_item, {
                          key: 1,
                          class: "multi-account-selector__item"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, [
                              _createVNode(_component_a_button, {
                                size: "small",
                                class: "cursor-pointer",
                                onClick: _ctx.handleAddAccount
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.mode === _ctx.MultiAccountSelectorMode.normal
                  ? _ctx.t("+ Add account")
                  : _ctx.t("Log in with another account")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.mode === _ctx.MultiAccountSelectorMode.normal)
                      ? (_openBlock(), _createBlock(_component_a_menu_item, {
                          key: 2,
                          class: "multi-account-selector__item"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_1, [
                              _createVNode(_component_a_button, {
                                size: "small",
                                class: "cursor-pointer",
                                onClick: _ctx.handleLogout,
                                loading: _ctx.logoutLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Logout")), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick", "loading"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            }
          : undefined
      ]), 1032, ["class"]))
    : _createCommentVNode("", true)
}