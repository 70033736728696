import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_VErrorBoundary = _resolveComponent("VErrorBoundary")!
  const _component_AuthenticatedLayout = _resolveComponent("AuthenticatedLayout")!
  const _component_NonAuthenticatedLayout = _resolveComponent("NonAuthenticatedLayout")!

  return (_ctx.ready)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (
        _ctx.isAuthenticated &&
        !_ctx.sharedAuthSession &&
        !_ctx.isUseNonAuthenticatedLayout &&
        !_ctx.userHasPendingAction
      )
            ? (_openBlock(), _createBlock(_component_AuthenticatedLayout, {
                key: 0,
                developer: _ctx.isDeveloperPage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VErrorBoundary, { "on-error": _ctx.handleError }, {
                    boundary: _withCtx(({ hasError }) => [
                      hasError
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Something Went Wrong"))
                        : _createCommentVNode("", true),
                      _createVNode(_component_router_view, null, {
                        default: _withCtx(({ Component }) => [
                          (_ctx.userInfoLoading)
                            ? (_openBlock(), _createBlock(_component_a_skeleton, {
                                key: 0,
                                active: "",
                                paragraph: { rows: 4 },
                                loading: _ctx.userInfoLoading,
                                class: "pa-40"
                              }, null, 8, ["loading"]))
                            : _createCommentVNode("", true),
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["on-error"])
                ]),
                _: 1
              }, 8, ["developer"]))
            : (_openBlock(), _createBlock(_component_NonAuthenticatedLayout, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}