import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.iconOnly ? _ctx.brandSimpleLogoSrc : _ctx.brandLogoSrc,
    alt: "brand-logo",
    class: "brand-logo-only",
    height: _ctx.height
  }, null, 8, _hoisted_1))
}